import React from 'react'
import { View,Text } from 'react-native'

class MenuHover extends React.Component {
  setStyles = (styles) => {
    this.root.setNativeProps({
      style: styles,
    })
  }

  state = {
    hover: false
  }

  render() {
    const { activeStyle, hoverStyle, style, onPressIn,color,name, onPressOut, ...passThrough } = this.props
    return (
      <View
        ref={(component) => { this.root = component }}
        onMouseEnter={
          () => {
            this.setStyles(hoverStyle)
            this.setState({ hover: true })
          }
        }
        onMouseLeave={
          () => {
            this.setStyles(style)
            this.setState({ hover: false })
          }
        }
        onStartShouldSetResponder={() => true}
        onResponderStart={
          () => {
            this.setStyles(activeStyle)
          }
        }
        onResponderRelease={
          () => {
            this.setStyles(this.state.hover ? hoverStyle : style)
          }
        }
        style={style}
        {...passThrough}
      >
      <Text style={{marginLeft:-90, marginTop:12, textAlign:'center',fontFamily:'SolaimanLipi', color:"#fff", fontWeight:'bold', }}>{this.state.name}</Text>   
      </View>
    )
  }
}

export default MenuHover